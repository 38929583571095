import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo/seo-new"

export default function Terms() {
    return (
        <Layout>


            <SEO
                title="Terms of Service"
                description="taxomate Terms of Service"
                image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
                pathname='/terms/'
                originalPathname='/terms/'
            />

            <div className="relative py-16 bg-white overflow-hidden">
                <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                    <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                        <svg
                            className="absolute top-12 left-full transform translate-x-32"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                        >
                            <defs>
                                <pattern
                                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                        </svg>
                        <svg
                            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                        >
                            <defs>
                                <pattern
                                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                        </svg>
                        <svg
                            className="absolute bottom-12 left-full transform translate-x-32"
                            width={404}
                            height={384}
                            fill="none"
                            viewBox="0 0 404 384"
                        >
                            <defs>
                                <pattern
                                    id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                        </svg>
                    </div>
                </div>


                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                        <h1 className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            Terms of Service
                        </h1>

                        <p className="pt-3 py-3 text-gray-500 text-base">
                            Please read these Terms carefully. By using Taxomate or signing up for an account, you’re agreeing to these Terms, which will result in a legal agreement between you and taxomate, LLC (“Taxomate”), which is organized under the laws of Delaware (“Agreement”). Taxomate offered through the URL taxomate.com (“Website”) is an automated cloud sync service used by Amazon merchants to import their Amazon sales and fee transactions and to post summarized transactions to Xero or QuickBooks accounting systems (the “Service”). Taxomate has employees, independent contractors, and representatives (“our Team”). As a customer of the Service or a representative of an entity that’s a customer of the Service, you’re a“Member” according to this Agreement (or “you”).
                        </p>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            These Terms of Use (“Terms”) define the terms and conditions under which you’re allowed to use the Service in accordance with the Agreement, and how we’ll treat your account while you’re a Member. If you don’t agree to these Terms, you must immediately discontinue your use of the Service.
                        </p>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            If you have any questions about our Terms, feel free to contact us.
                        </p>

                        <h2 className="pt-6 py-6 text-gray-500 text-xl pb-3 font-bold">
                            Account
                        </h2>

                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            1. Eligibility
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            In order to use the Service, you must:
                        </p>
                        <ul class="list-disc px-5">
                            <li className="text-gray-500 text-base">
                                be at least eighteen (18) years old and able to enter into contracts;
                            </li>
                            <li className="text-gray-500 text-base">
                                complete the registration process;
                            </li>
                            <li className="text-gray-500 text-base">
                                agree to these Terms;
                            </li>
                            <li className="text-gray-500 text-base">
                                provide true, complete, and up-to-date contact and billing information; and
                            </li>
                            <li className="text-gray-500 text-base">
                                not be based in Cuba, Iran, North Korea, Syria, or any other country that is subject to a U.S. government embargo, or that has been designated by the U.S.government as a “terrorist-supporting” country.
                            </li>
                        </ul>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            If you sign up for the Service on behalf of a company or other entity, you represent and warrant that you have the authority to accept these Terms and enter into the Agreement on its behalf.
                        </p>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            By using the Service, you represent and warrant that you meet all the requirements listed above and that you won’t use the Service in a way that violates any laws or regulations.
                        </p>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            Taxomate may refuse service, close accounts of any users, and change eligibility requirements at any time.
                        </p>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            BY checking the box that states “I have read and agree to the
                            <a href="/terms" className="text-blue-600"> Terms of Service</a>,
                            <a href="https://www.iubenda.com/privacy-policy/77958510/legal?ifr=true&height=498&newmarkup=no" className="text-blue-600">  Privacy Policy </a>and
                            <a href="https://www.iubenda.com/privacy-policy/77958510/cookie-policy" className="text-blue-600"> Cookie Policy</a>
                            ” and clicking the submit button means that you’ve officially “signed” and accepted the Terms.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            2. Term
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            When you sign up for the Service and agree to these Terms, the Agreement between you and Taxomate is formed, and the term of the Agreement (the “Term”) will begin. The Term will continue for as long as you have a Taxomate account or until you or we terminate the Agreement in accordance with these Terms, whichever happens first.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            3. Closing Your Account
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            You or Taxomate may terminate the Agreement at any time and for any reason by terminating your Taxomate account or giving notice to the other party. We may suspend the Service to you at any time, with or without cause. If we terminate your account without cause, and your account is a paid account, we’ll refund a prorated portion of your monthly prepayment. We won’t refund or reimburse you in any other situation, including if your account is suspended or terminated for cause, like a breach or any violation of the Agreement. Once your account is terminated, you acknowledge and agree that we may permanently delete your account and all the data associated with it. Usernames are unique and can only be used once. If your account has been terminated, the username will no longer be available for use on any future accounts and cannot be reclaimed.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            4. Changes
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            We may change any of the Terms by posting revised Terms of Use on our Website and/or by notifying you of the new Terms by sending an email to the last email address you gave us or displaying a prominent notice within the Service. Unless you terminate your account within 10 days, the new Terms will be effective immediately and apply to any continued or new use of the Service. We may change the Website, the Service, Add-ons, or any features of the Service at any time, and we may discontinue the Website, the Service, Add-ons, or any features of the Service at any time.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            5. Account and Password
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            You’re responsible for keeping your account name and password confidential. You’re also responsible for any account that you have access to and any activity occurring in such an account (other than activity thatTaxomate is directly responsible for that isn’t performed in accordance with your instructions), whether or not you authorized that activity. You’ll immediately notify us of any unauthorized access or use of your accounts. We’re not responsible for any losses due to stolen or hacked passwords that are caused by or result from your negligence. We don’t have access to your current password, and for security reasons, we may only provide you with instructions on how to reset your password. We have the right to update any of your contact information in your account for billing purposes. In addition, you represent and warrant that all information you provide to us when you establish an account, and when you access and use the Service, is and will remain complete and accurate.
                        </p>
                        <h2 className="pt-6 py-6 text-gray-500 font-bold text-xl pb-3">
                            Payment
                        </h2>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            6. Monthly Plans
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            When you sign up for a Monthly Plan, you agree to recurring billing. Billing occurs on the same day each month, based on the date that you started the Monthly Plan. Billing for Monthly Plans may be paused, and you may choose to close your account permanently at any time.
                        </p>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            Our charges for Monthly Plans are <a href="/terms" className="text-blue-600"> posted</a> on our Website and may be changed from time to time. If any part of a month is included in the Term, then payment is due for the full month. Payments are due for any month on the same or closest date to the day you made your first monthly payment (the “Pay Date”). If you go over your limit and reach another pricing level, then you’ll have to pay at the higher level on or before the next Pay Date. If the Term ends before that payment is due, you’ll still be required to make one payment at the higher level. If the Term ends before that payment is due, you’ll still be required to make one payment at the higher level.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            7. Credit Cards
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            As long as you’re a Member with a paid account or otherwise have an outstanding balance with us, you’ll provide us with valid credit card information or other payment source information and authorize us to deduct the monthly charges against that credit card or other payment source. You’ll replace the information for any credit card that expires with information for a valid one. If your credit card is automatically replaced with a new card by a payment processor, you acknowledge and agree that we’re authorized to deduct any charges on your account against the new credit card. Anyone using a credit card or other payment source represents and warrants that they are authorized to use that credit card, or other payment sources, that any and all charges may be billed to that credit card or other payment source and won’t be rejected. If we’re unable to process your credit card or payment source order, we’ll try to contact you by email and suspend your account until your payment can be processed.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            8. Refunds
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            We’ll give you a refund for a prepaid month if we stop providing the Service and terminate your account without cause. You won’t be entitled to a refund from us under any other circumstances. We may, at our sole discretion, offer a refund if a Member requests one.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            9 Billing Charges
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            We may change our fees at any time by posting a new pricing structure to our Website and/or sending you a notification by email. Quoted fees don’t include sales or other transaction-based taxes of any kind.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            10 Proprietary Rights Owned by Us
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            You will respect our proprietary rights in the Website and the software used to provide the Service (proprietary rights include, but aren’t limited to, patents, trademarks, service marks, trade secrets, copyrights, and other intellectual property).
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            11. Proprietary Rights Owned by You
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            You represent and warrant that you either own or have permission to use all of the material, content, data, and information(including your personal information and the personal information of others)you submit to Taxomate in the course of using the Service (“Content”). You retain ownership of the Content that you upload to the Service. We may use or disclose your Content (including any personal information therein) only as described in these
                            <a href="/terms" className="text-blue-600"> Terms of Service</a>,
                            <a href="https://www.iubenda.com/privacy-policy/77958510/legal?ifr=true&height=498&newmarkup=no" className="text-blue-600">  Privacy Policy </a> and
                            <a href="https://www.iubenda.com/privacy-policy/77958510/cookie-policy" className="text-blue-600"> Cookie Policy</a>
                            , and, if applicable, our Data Processing Addendum.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            12. Privacy Policy
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            Your privacy is important to us. Please read our
                            <a href="https://www.iubenda.com/privacy-policy/77958510/legal?ifr=true&height=498&newmarkup=no" className="text-blue-600">  Privacy Policy </a> and
                            <a href="https://www.iubenda.com/privacy-policy/77958510/cookie-policy" className="text-blue-600"> Cookie Policy</a>
                            for information regarding how we collect, use, and disclose your Content and personal information and protect your privacy when you use the Service.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            13. Compliance with Laws
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            You represent and warrant that your use of the Service will comply with all applicable laws and regulations. You’re responsible for determining whether the Service is suitable for you to use in light of your obligations under any regulations like HIPAA, GLB, EU data privacy laws(including the General Data Protection Regulation) (collectively, “EU Data Privacy Laws”), United States export control laws and regulations and economic sanctions laws and regulations (“U.S. Export Control Laws and Regulations”), or other applicable laws. If you’re subject to regulations (like HIPAA) and you use the Service, then we won’t be liable if the Service doesn’t meet those requirements. You may not use the Service for any unlawful activities, including acts prohibited by the Federal Trade Commission Act, Fair Credit Reporting Act, Equal Credit Opportunity Act, Children’s Online Privacy Protection Act, or other laws that apply to commerce.
                        </p>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            If you collect any personal information pertaining to a minor and store such information within your Taxomate account, you represent and warrant that you have obtained valid consent from the minor according to the applicable laws of the jurisdiction in which the minor lives, or that you have otherwise obtained the required parental or guardian consent according to the applicable laws of the jurisdiction in which the minor lives.
                        </p>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            You agree to indemnify and hold us harmless from any losses, including all legal fees and expenses, that result from your breach of this Section 13.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            14. Limitation of Liability
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            To the maximum extent permitted by law, you assume full responsibility for any loss that results from your use of the Website and the service, including any downloads from the Website. We and our Team won’t be liable for any indirect, punitive, special, or consequential damages under any circumstances, even if they’re based on negligence or we’ve been advised of the possibility of those damages. In any calendar month, our total liability to you arising under or in connection with the Agreement—whether in contract, tort (including negligence), breach of statutory duty, or otherwise—will be no more than what you paid us for the Service the preceding month.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            15. No Warranties
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            To the maximum extent permitted by law, we provide the Website and the Service as-is. This means that, except as expressly stated in these Terms, we don’t provide warranties, conditions, or undertakings of any kind in relation to the Website and/or Service, either express or implied. This includes, but isn’t limited to, warranties of merchantability and fitness for a particular purpose, which are to the fullest extent permitted by law, excluded from the Agreement. Since Members use the Service for a variety of reasons, we can’t guarantee that it’ll meet your specific needs.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            16. Indemnity
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            You agree to indemnify and hold us and our Team harmless from any losses, including legal fees and expenses that directly or indirectly result from any claims you make that aren’t allowed under these Terms due to a“Limitation of Liability” or other provision. (Indemnity is an agreement to compensate someone for a loss.) You also agree to indemnify and hold us harmless from any losses, including legal fees and expenses, that directly or indirectly result from (a) your Content, (b) your use of the Service, (c) your violation of any laws or regulations, (d) third-party claims that you or someone using your password did something that, if true, would violate any of these Terms, (e) any misrepresentations made by you, or (f) a breach of any representations or warranties you’ve made to us.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            17. Legal Fees and Expenses
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            If we file an action against you claiming you breached these Terms and we prevail, we’re entitled to recover all reasonable legal fees, expenses, and any damages or other relief we may be awarded.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            18. Equitable Relief
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            If you violate these Terms, then we may seek injunctive relief (meaning we may request a court order to stop you) or other equitable relief.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            19. Subpoena Fees
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            If we have to provide information in response to a subpoena, court order, or other legal, governmental, or regulatory inquiry related to your account, then we may charge you for our costs. These fees may include attorney and employee time spent retrieving the records, preparing documents, and participating in a deposition.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            20. Disclaimers
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            We and our Team aren’t responsible for the behavior of any third parties, linked websites, or other Members.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            21. Assignments
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            You may not assign any of your rights under this agreement to anyone else. We may assign our rights to any other individual or entity at our discretion.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            22. Choice of Law
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            The State of Delaware’s laws, except for conflict of laws rules, will apply to any dispute related to the Agreement or the Service. Any dispute related to the Agreement or the Service itself will be decided by the state and federal courts in Wilmington, Delaware, and each party will be subject to the jurisdiction of those courts.
                        </p>
                        <p className="pt-3 py-3 text-gray-500 font-bold text-base">
                            EACH PARTY WAIVES THE RIGHT TO LITIGATE IN COURT OR ARBITRATE ANY CLAIM OR DISPUTE AS A CLASS ACTION, EITHER AS A MEMBER OF A CLASS OR AS A REPRESENTATIVE OR TO ACT AS A PRIVATE ATTORNEY GENERAL.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            23. Force Majeure
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            We won’t be held liable for any delays or failure in performance of any part of the Service, from any cause beyond our control. This includes, but is not limited to, acts of god, changes to law or regulations, embargoes, war, terrorist acts, riots, fires, earthquakes, nuclear accidents, floods, strikes, power blackouts, volcanic action, unusually severe weather conditions, and acts of hackers, or third-party internet service providers.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            24. Survivability
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            Even if this Agreement is terminated, the following sections will continue to apply: Proprietary Rights Owned by Us, Proprietary Rights Owned by You, Compliance with Laws, Limitation of Liability, No Warranties, Indemnity, Choice of Law, Severability, and Entire Agreement.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            25. Severability
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            If it turns out that a section of these Terms isn’t enforceable, then that section will be removed or edited as little as required, and the rest of the Agreement will still be valid.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            26. Interpretation
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            The headers are provided only to make these Terms easier to read and understand. The fact that we wrote these Terms won’t affect the way the Agreement is interpreted.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            27. Amendments and Waiver
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            Amendments or changes to the Agreement won’t be effective until we post revised Terms on the Website. If we don’t immediately take action on a violation of these Terms, we’re not giving up any rights under the Terms, and we may still take action at some point.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            28. No Changes in Terms at Request of Member
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            We will not change these Terms for any one Member or group.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            29. Further Actions
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            You’ll provide all documents and take any actions necessary to meet your obligations under these Terms.
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            30. Notices
                        </h3>

                        <p className="pt-3 py-3 text-gray-500 text-base">
                            Any notice to you will be effective when we send it to the last email or physical address you gave us or when posted on our Website. Any notice to us will be effective when delivered to us along with a copy to our legal counsel:
                            <ul class="list-disc px-5">
                                <li className="text-gray-500 text-base">
                                    Attn. Legal Department, Taxomate, 442 5th Avenue #1003 New York, NY 10018
                                </li>
                                <li className="text-gray-500 text-base">
                                    Email address: legal@taxomate.com or any addresses as we may later post on the Website.
                                </li>
                            </ul>
                        </p>
                        <h3 className="pt-3 py-3 text-gray-500 text-lg">
                            31. Entire Agreement
                        </h3>
                        <p className="pt-3 py-3 text-gray-500 text-base">
                            The Agreement makes up the entire agreement between us in relation to its subject matter and supersedes all prior agreements, representations, and understandings.
                        </p>




                    </div>
                </div>
            </div>



        </Layout>
    );
}
